<template>
    <div class="widget__item widget__learning dashboard__block">
        <div class="learning__header">
            <h2 class="h2 learning__title">На изучении</h2>
            <span class="learning__count">{{learning.count}}</span>
        </div>
        <ul class="learning__list" v-if="learning.count > 0">
            <li
                v-for="article in learning.list"
                class="learning__item"
            >
                <router-link :to="article.link" class="learning__link" :title="article.title">
                    <span class="learning__link-icon"/>
                    <span class="learning__link-text">{{article.title}}</span>
                </router-link>
            </li>
        </ul>
        <div
            class="learning__empty"
            v-if="learning.count === 0"
        >
            <h3 class="learning__empty-title">Нет статей на изучении</h3>
            <router-link to="/knowledge/" class="learning__empty-link">Перейти в базу знаний</router-link>
        </div>
        <router-link to="/knowledge/?lean=1" v-if="learning.count > 4" class="learning__more">
            Ещё {{learning.count - 4}}
        </router-link>
    </div>
</template>
<script>
    import {mapState} from "vuex"

    export default {
        name: 'LearningWidget',
        computed: {
            ...mapState('wrapper_materials', [
              'learning'
            ]),
        }
    }

</script>
<style lang="sass" scoped>
    @import "#sass/v-style"

    .widget
        &__learning
            display: flex
            flex-direction: column
            height: 350px

    .learning
        &__header
            display: flex
            align-items: baseline

        &__title
            margin-right: 10px

        &__count
            color: $text-gray

        &__list
            margin-top: 30px

        &__item
            display: flex
            align-items: center
            font-size: 1rem
            overflow: hidden
            padding:
                top: 16px

            &:first-of-type
                padding-top: 0

            &:nth-last-of-type(n+2)
                border-bottom: 1px solid $border-gray
                padding-bottom: 20px

        &__link
            font-size: 1rem
            color: #000
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            display: flex
            align-items: center
            &-text
                overflow: hidden
                text-overflow: ellipsis
            &-icon
                @include hard-size(5px,5px)
                border-radius: 50%
                margin-right: 10px
                background-color: #FFB71C
                background-repeat: no-repeat
                background-size: cover
            &:hover
                color: #1568ac

        &__more
            font-size: 1rem
            margin-top: auto

        &__empty
            text-align: center
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            height: 100%
            &-title
                color: $text-gray
                margin:
                    bottom: 0
                    top: auto
            &-link
                margin-top: auto
    @media (max-width: 500px)
        .learning
            &__empty
                &-title
                    font-size: 1.25rem
                &-link
                    font-size: 1rem

</style>
